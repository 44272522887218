export const portfolioStatic = {
  crumbsNav: [
    {
      id: 1,
      link: '/',
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
    },
    {
      id: 2,
      link: '/portfolio/',
      title_ua: 'Портфоліо',
      title_ru: 'Портфолио',
      title_en: 'Portfolio',
    },
    
  ],
};

// export default portfolioStatic;
